import { getItemWithExpiry } from "@cospex/client/helpers";
import useTranslation from "@cospex/client/hooks/useTranslation";
import SearchPhone from "@cospex/client/reverse/SearchPhone";
import { Section } from "@cospex/client/reverse/components/Layout";
import PhoneGraphic from "@cospex/client/reverse/components/PhoneGraphic";
import TiltPlane from "@cospex/client/reverse/components/TiltPane";
import HeroImageB from "@cospex/client/reverse/img/hero-foreground.png";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useState } from "react";

interface PhoneImageProps {
  number: string;
  country: string;
}

const PhoneImage = ({ number, country }: PhoneImageProps) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        marginX: "auto",
        aspectRatio: "1",
        transform: "scale(1.4)",
        mt: { xs: 5, sm: 8, md: 0 },
        maxHeight: 580,
      }}
    >
      <PhoneGraphic
        number={number}
        country={country}
        phoneImageSrc={HeroImageB}
      />
    </Box>
  );
};

interface DashboardSearchProps {
  onTrack: () => void;
}

export default function DashboardSearch({ onTrack }: DashboardSearchProps) {
  const { t } = useTranslation();
  const [number, setNumber] = useState(
    getItemWithExpiry("onboardingNumber") || "06 XX XX XX XX"
  );
  const [country, setCountry] = useState("");

  return (
    <Box
      sx={{
        pt: "5vw",
      }}
    >
      <Section>
        <Typography variant="h3" color="primary.main" mb={4}>
          {t("dashboard-sms-title")}
        </Typography>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box>
              <Box mb={2}>
                <Typography variant="h6" gutterBottom>
                  {t("dashboard-sms-confirm-phone")}
                </Typography>
              </Box>
              <SearchPhone
                onTrack={onTrack}
                onNumberChange={setNumber}
                onCountryChange={setCountry}
                business="reverse"
              />
              <br />
              <Typography>{t("phone-form-note")}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <PhoneImage number={number} country={country} />
          </Grid>
        </Grid>
      </Section>
    </Box>
  );
}
